<template>
  <div class="container">
    <h2>Add A VehicleVariants</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="Description">Description</label
        ><input
          class="form-control"
          id="Description"
          name="Description"
          v-model="newVehicleVariants.Description"
          placeholder="Description"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="ModelId">ModelId</label
        ><input
          class="form-control"
          id="ModelId"
          name="ModelId"
          v-model="newVehicleVariants.ModelId"
          placeholder="ModelId"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Price">Price</label
        ><input
          class="form-control"
          id="Price"
          name="Price"
          v-model="newVehicleVariants.Price"
          placeholder="Price"
          tabindex="0"
          type="text"
          value=""
        />
             <label class="" for="PriceNamibia">PriceNamibia</label>
         <input
          class="form-control"
          id="PriceNamibia"
          name="PriceNamibia"
          v-model="newVehicleVariants.PriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
           <label class="" for="PriceBotswana">PriceBotswana</label>
            <input
          class="form-control"
          id="PriceBotswana"
          name="PriceBotswana"
          v-model="newVehicleVariants.PriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
           <label class="" for="PriceSwaziland">PriceSwaziland</label>
              <input
          class="form-control"
          id="PriceSwaziland"
          name="PriceSwaziland"
          v-model="newVehicleVariants.PriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="MetalicPaintPrice">MetalicPaintPrice</label
        ><input
          class="form-control"
          id="MetalicPaintPrice"
          name="MetalicPaintPrice"
          v-model="newVehicleVariants.MetalicPaintPrice"
          placeholder="MetalicPaintPrice"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="PowerKW">PowerKW</label
        ><input
          class="form-control"
          id="PowerKW"
          name="PowerKW"
          v-model="newVehicleVariants.PowerKW"
          placeholder="PowerKW"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="TorqueNM">TorqueNM</label
        ><input
          class="form-control"
          id="TorqueNM"
          name="TorqueNM"
          v-model="newVehicleVariants.TorqueNM"
          placeholder="TorqueNM"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Displacement">Displacement</label
        ><input
          class="form-control"
          id="Displacement"
          name="Displacement"
          v-model="newVehicleVariants.Displacement"
          placeholder="Displacement"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="MaxSpeed">MaxSpeed</label
        ><input
          class="form-control"
          id="MaxSpeed"
          name="MaxSpeed"
          v-model="newVehicleVariants.MaxSpeed"
          placeholder="MaxSpeed"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Transmission">Transmission</label
        ><input
          class="form-control"
          id="Transmission"
          name="Transmission"
          v-model="newVehicleVariants.Transmission"
          placeholder="Transmission"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="FuelConsumption">FuelConsumption</label
        ><input
          class="form-control"
          id="FuelConsumption"
          name="FuelConsumption"
          v-model="newVehicleVariants.FuelConsumption"
          placeholder="FuelConsumption"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="FuelType">FuelType</label
        ><input
          class="form-control"
          id="FuelType"
          name="FuelType"
          v-model="newVehicleVariants.FuelType"
          placeholder="FuelType"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="BootSpace">BootSpace</label
        ><input
          class="form-control"
          id="BootSpace"
          name="BootSpace"
          v-model="newVehicleVariants.BootSpace"
          placeholder="BootSpace"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="PowerWindows">PowerWindows</label
        ><input
          class="form-control"
          id="PowerWindows"
          name="PowerWindows"
          v-model="newVehicleVariants.PowerWindows"
          placeholder="PowerWindows"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Airbags">Airbags</label
        ><input
          class="form-control"
          id="Airbags"
          name="Airbags"
          v-model="newVehicleVariants.Airbags"
          placeholder="Airbags"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="ABS">ABS</label
        ><input
          class="form-control"
          id="ABS"
          name="ABS"
          v-model="newVehicleVariants.ABS"
          placeholder="ABS"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="CentralLocking">CentralLocking</label
        ><input
          class="form-control"
          id="CentralLocking"
          name="CentralLocking"
          v-model="newVehicleVariants.CentralLocking"
          placeholder="CentralLocking"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="FogLamps">FogLamps</label
        ><input
          class="form-control"
          id="FogLamps"
          name="FogLamps"
          v-model="newVehicleVariants.FogLamps"
          placeholder="FogLamps"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="BoreStroke">BoreStroke</label
        ><input
          class="form-control"
          id="BoreStroke"
          name="BoreStroke"
          v-model="newVehicleVariants.BoreStroke"
          placeholder="BoreStroke"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="KeyFeatures">KeyFeatures</label
        ><input
          class="form-control"
          id="KeyFeatures"
          name="KeyFeatures"
          v-model="newVehicleVariants.KeyFeatures"
          placeholder="KeyFeatures"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Features">Features</label
        ><input
          class="form-control"
          id="Features"
          name="Features"
          v-model="newVehicleVariants.Features"
          placeholder="Features"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="SeatingCapacity">SeatingCapacity</label
        ><input
          class="form-control"
          id="SeatingCapacity"
          name="SeatingCapacity"
          v-model="newVehicleVariants.SeatingCapacity"
          placeholder="SeatingCapacity"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="BodyType">BodyType</label
        ><input
          class="form-control"
          id="BodyType"
          name="BodyType"
          v-model="newVehicleVariants.BodyType"
          placeholder="BodyType"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="LastUpdated">LastUpdated</label
        ><input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="newVehicleVariants.LastUpdated"
          placeholder="LastUpdated"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>

      <div class="col-md-4">
        <label class for="SeatingCapacity">Brochure</label>
        <ImageUploader
          title="Brochure"
          :allFiles="true"
          v-model="newVehicleVariants.BrochureLink"
        />
      </div>
      <div class="col-md-4">
        <label class for="BodyType">Specsheet</label>
        <ImageUploader
         :pdf="true"
          title="Specsheet"
          :allFiles="true"
          v-model="newVehicleVariants.SpecSheetLink"
        />
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">Accessories</label>
        <ImageUploader
         :pdf="true"
          title="Accessories"
          :allFiles="true"
          v-model="newVehicleVariants.AccessoriesLink"
        />
      </div>
      <div class="col-md-4">
        <label class for="Image">Image</label>
        <div v-if="!editingImage">
          <img
            v-bind:src="getImageSource(newVehicleVariants)"
            class="previewImage"
          />
          <button @click="editImage()" type="button" class="btn btn-success">
            Update Image
          </button>
        </div>
        <div v-if="editingImage">
          <button @click="updateImage()" type="button" class="btn btn-success">
            Update
          </button>
          <button @click="editImage()" type="button" class="btn btn-warning">
            Cancel
          </button>
          <FileUploader
            @ImageDataChanged="updateImageData"
            @ImageURLDataChanged="updateImageUrl"
          />
        </div>
      </div>

      <div class="col-md-4 pt-2">
        <label class for="TechnicalFeatures">Technical Features</label>
        <textarea
          class="form-control"
          id="TechnicalFeatures"
          name="TechnicalFeatures"
          v-model="newVehicleVariants.TechnicalFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="TechnicalFeaturesOrder"
          >Technical Features Order</label
        >
        <input
          class="form-control"
          id="TechnicalFeaturesOrder"
          name="TechnicalFeaturesOrder"
          v-model="newVehicleVariants.TechnicalFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="PerformanceFeatures">Performance Features</label>
        <textarea
          class="form-control"
          id="PerformanceFeatures"
          name="PerformanceFeatures"
          v-model="newVehicleVariants.PerformanceFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="PerformanceFeaturesOrder"
          >Performance Features Order</label
        >
        <input
          class="form-control"
          id="PerformanceFeaturesOrder"
          name="PerformanceFeaturesOrder"
          v-model="newVehicleVariants.PerformanceFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="ConvenienceFeatures">Convenience Features</label>
        <textarea
          class="form-control"
          id="ConvenienceFeatures"
          name="ConvenienceFeatures"
          v-model="newVehicleVariants.ConvenienceFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="ConvenienceFeaturesOrder"
          >Convenience Features Order</label
        >
        <input
          class="form-control"
          id="ConvenienceFeaturesOrder"
          name="ConvenienceFeaturesOrder"
          v-model="newVehicleVariants.ConvenienceFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="StylingFeatures">Styling Features</label>
        <textarea
          class="form-control"
          id="StylingFeatures"
          name="StylingFeatures"
          v-model="newVehicleVariants.StylingFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="StylingFeaturesOrder">Styling Features Order</label>
        <input
          class="form-control"
          id="StylingFeaturesOrder"
          name="StylingFeaturesOrder"
          v-model="newVehicleVariants.StylingFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="SafetyFeatures">Safety Features</label>
        <textarea
          class="form-control"
          id="SafetyFeatures"
          name="SafetyFeatures"
          v-model="newVehicleVariants.SafetyFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="SafetyFeaturesOrder">Safety Features Order</label>
        <input
          class="form-control"
          id="SafetyFeaturesOrder"
          name="SafetyFeaturesOrder"
          v-model="newVehicleVariants.SafetyFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="WarrantyAndServiceFeatures"
          >Warranty And Service Features</label
        >
        <textarea
          class="form-control"
          id="WarrantyAndServiceFeatures"
          name="WarrantyAndServiceFeatures"
          v-model="newVehicleVariants.WarrantyAndServiceFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="WarrantyAndServiceFeaturesOrder"
          >Warranty And Service Features Order</label
        >
        <input
          class="form-control"
          id="WarrantyAndServiceFeaturesOrder"
          name="WarrantyAndServiceFeaturesOrder"
          v-model="newVehicleVariants.WarrantyAndServiceFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <br />
    </div>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  name: "addVehicleVariants",
  created: function () {},
  data() {
    return {
      newVehicleVariants: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
    ImageUploader,
  },
  methods: {
    toggleView: function () {
      this.$emit("closeAddVehicleVariants");
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    save: function () {
      var payload = {
        data: this.newVehicleVariants,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("addVehicleVariantsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("addVehicleVariants", payload);
    },
  },
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
