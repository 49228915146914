<template>
  <div class="container">
    <h2>Edit VehicleVariants</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button
      @click="deleteVehicleVariants()"
      type="button"
      class="btn btn-danger"
    >
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="ModelId">Vehicle</label>

        <VehicleModelSelect v-model="editingVehicleVariants.ModelId" />
      </div>
      <div class="col-md-4">
        <label class for="Description">Description</label>
        <input
          class="form-control"
          id="Description"
          name="Description"
          v-model="editingVehicleVariants.Description"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-4">
        <label class for="Price">Price</label>
        <input
          class="form-control"
          id="Price"
          name="Price"
          v-model="editingVehicleVariants.Price"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="PriceNamibia">PriceNamibia</label>
        <input
          class="form-control"
          id="PriceNamibia"
          name="PriceNamibia"
          v-model="editingVehicleVariants.PriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
        <label class for="PriceBotswana">PriceBotswana</label>
        <input
          class="form-control"
          id="PriceBotswana"
          name="PriceBotswana"
          v-model="editingVehicleVariants.PriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
        <label class for="PriceSwaziland">PriceSwaziland</label>
        <input
          class="form-control"
          id="PriceSwaziland"
          name="PriceSwaziland"
          v-model="editingVehicleVariants.PriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="MetalicPaintPrice">MetalicPaintPrice</label>
        <input
          class="form-control"
          id="MetalicPaintPrice"
          name="MetalicPaintPrice"
          v-model="editingVehicleVariants.MetalicPaintPrice"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="PowerKW">PowerKW</label>
        <input
          class="form-control"
          id="PowerKW"
          name="PowerKW"
          v-model="editingVehicleVariants.PowerKW"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="TorqueNM">TorqueNM</label>
        <input
          class="form-control"
          id="TorqueNM"
          name="TorqueNM"
          v-model="editingVehicleVariants.TorqueNM"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Displacement">Displacement</label>
        <input
          class="form-control"
          id="Displacement"
          name="Displacement"
          v-model="editingVehicleVariants.Displacement"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="MaxSpeed">MaxSpeed</label>
        <input
          class="form-control"
          id="MaxSpeed"
          name="MaxSpeed"
          v-model="editingVehicleVariants.MaxSpeed"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Transmission">Transmission</label>
        <input
          class="form-control"
          id="Transmission"
          name="Transmission"
          v-model="editingVehicleVariants.Transmission"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="FuelConsumption">FuelConsumption</label>
        <input
          class="form-control"
          id="FuelConsumption"
          name="FuelConsumption"
          v-model="editingVehicleVariants.FuelConsumption"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="FuelType">FuelType</label>
        <input
          class="form-control"
          id="FuelType"
          name="FuelType"
          v-model="editingVehicleVariants.FuelType"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="BootSpace">BootSpace</label>
        <input
          class="form-control"
          id="BootSpace"
          name="BootSpace"
          v-model="editingVehicleVariants.BootSpace"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="PowerWindows">PowerWindows</label>
        <input
          class="form-control"
          id="PowerWindows"
          name="PowerWindows"
          v-model="editingVehicleVariants.PowerWindows"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Airbags">Airbags</label>
        <input
          class="form-control"
          id="Airbags"
          name="Airbags"
          v-model="editingVehicleVariants.Airbags"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="ABS">ABS</label>
        <input
          class="form-control"
          id="ABS"
          name="ABS"
          v-model="editingVehicleVariants.ABS"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="CentralLocking">CentralLocking</label>
        <input
          class="form-control"
          id="CentralLocking"
          name="CentralLocking"
          v-model="editingVehicleVariants.CentralLocking"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="FogLamps">FogLamps</label>
        <input
          class="form-control"
          id="FogLamps"
          name="FogLamps"
          v-model="editingVehicleVariants.FogLamps"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="BoreStroke">BoreStroke</label>
        <input
          class="form-control"
          id="BoreStroke"
          name="BoreStroke"
          v-model="editingVehicleVariants.BoreStroke"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="KeyFeatures">KeyFeatures</label>
        <input
          class="form-control"
          id="KeyFeatures"
          name="KeyFeatures"
          v-model="editingVehicleVariants.KeyFeatures"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Features">Features</label>
        <input
          class="form-control"
          id="Features"
          name="Features"
          v-model="editingVehicleVariants.Features"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="SeatingCapacity">SeatingCapacity</label>
        <input
          class="form-control"
          id="SeatingCapacity"
          name="SeatingCapacity"
          v-model="editingVehicleVariants.SeatingCapacity"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="BodyType">BodyType</label>
        <input
          class="form-control"
          id="BodyType"
          name="BodyType"
          v-model="editingVehicleVariants.BodyType"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingVehicleVariants.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-4">
        <ImageUploader
          label="Brochure"
          :allFiles="true"
          v-model="editingVehicleVariants.BrochureLink"
        />
      </div>
      <div class="col-md-4">
        <ImageUploader
          label="Specsheet"
          :pdf="true"
          :allFiles="true"
          v-model="editingVehicleVariants.SpecSheetLink"
        />
      </div>
      <div class="col-md-4">
        <ImageUploader
          :pdf="true"
          label="Accessories"
          :allFiles="true"
          v-model="editingVehicleVariants.AccessoriesLink"
        />
      </div>

      <div class="col-md-4">
        <ImageUploader
          label="Image"
          :allFiles="true"
          v-model="editingVehicleVariants.Image"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="TechnicalFeatures">Technical Features</label>
        <textarea
          class="form-control"
          id="TechnicalFeatures"
          name="TechnicalFeatures"
          v-model="editingVehicleVariants.TechnicalFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="TechnicalFeaturesOrder"
          >Technical Features Order</label
        >
        <input
          class="form-control"
          id="TechnicalFeaturesOrder"
          name="TechnicalFeaturesOrder"
          v-model="editingVehicleVariants.TechnicalFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="PerformanceFeatures">Performance Features</label>
        <textarea
          class="form-control"
          id="PerformanceFeatures"
          name="PerformanceFeatures"
          v-model="editingVehicleVariants.PerformanceFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="PerformanceFeaturesOrder"
          >Performance Features Order</label
        >
        <input
          class="form-control"
          id="PerformanceFeaturesOrder"
          name="PerformanceFeaturesOrder"
          v-model="editingVehicleVariants.PerformanceFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="ConvenienceFeatures">Convenience Features</label>
        <textarea
          class="form-control"
          id="ConvenienceFeatures"
          name="ConvenienceFeatures"
          v-model="editingVehicleVariants.ConvenienceFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="ConvenienceFeaturesOrder"
          >Convenience Features Order</label
        >
        <input
          class="form-control"
          id="ConvenienceFeaturesOrder"
          name="ConvenienceFeaturesOrder"
          v-model="editingVehicleVariants.ConvenienceFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="StylingFeatures">Styling Features</label>
        <textarea
          class="form-control"
          id="StylingFeatures"
          name="StylingFeatures"
          v-model="editingVehicleVariants.StylingFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="StylingFeaturesOrder">Styling Features Order</label>
        <input
          class="form-control"
          id="StylingFeaturesOrder"
          name="StylingFeaturesOrder"
          v-model="editingVehicleVariants.StylingFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="SafetyFeatures">Safety Features</label>
        <textarea
          class="form-control"
          id="SafetyFeatures"
          name="SafetyFeatures"
          v-model="editingVehicleVariants.SafetyFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="SafetyFeaturesOrder">Safety Features Order</label>
        <input
          class="form-control"
          id="SafetyFeaturesOrder"
          name="SafetyFeaturesOrder"
          v-model="editingVehicleVariants.SafetyFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>

      <div class="col-md-4 pt-2">
        <label class for="WarrantyAndServiceFeatures"
          >Warranty And Service Features</label
        >
        <textarea
          class="form-control"
          id="WarrantyAndServiceFeatures"
          name="WarrantyAndServiceFeatures"
          v-model="editingVehicleVariants.WarrantyAndServiceFeatures"
          placeholder=" "
          tabindex="0"
        ></textarea>

        <label class for="WarrantyAndServiceFeaturesOrder"
          >Warranty And Service Features Order</label
        >
        <input
          class="form-control"
          id="WarrantyAndServiceFeaturesOrder"
          name="WarrantyAndServiceFeaturesOrder"
          v-model="editingVehicleVariants.WarrantyAndServiceFeaturesOrder"
          placeholder=" "
          tabindex="0"
          type="number"
          style="width: 50px"
        />
      </div>
    </div>
    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import VehicleModelSelect from "@/components/custom/VehicleModelSelect.vue";

import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditVehicleVariants",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
    ImageUploader,
    VehicleModelSelect,
  },
  created() {
    this.originalData = JSON.parse(JSON.stringify(this.editingVehicleVariants));
    this.ImageBytes = this.editingVehicleVariants.Image;
  },

  props: ["editingVehicleVariants"],

  methods: {
    toggleView: function () {
      this.$emit("closeEditVehicleVariants");
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingVehicleVariants));
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("editVehicleVariantsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("editVehicleVariants", payload);
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deleteVehicleVariants: function () {
      var payload = {
        data: this.editingVehicleVariants,
        success: (response) => {
          this.$emit("editVehicleVariantsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteVehicleVariants", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
