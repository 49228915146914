<template>
  <div class="card">
    <div class="container">
      <b-modal ref="addVehicleVariantsModal" hide-footer>
        <addVehicleVariants
          @closeAddVehicleVariants="toggleAddVehicleVariants()"
          @addVehicleVariantsSuccess="addVehicleVariantsSuccess()"
        ></addVehicleVariants>
      </b-modal>
      <b-modal ref="editVehicleVariantsModal" hide-footer>
        <editVehicleVariants
          :editingVehicleVariants="currentVehicleVariants"
          @closeEditVehicleVariants="toggleEditVehicleVariants()"
          @editVehicleVariantsSuccess="editVehicleVariantsSuccess()"
        ></editVehicleVariants>
      </b-modal>
      <div class="row">
        <h2>VehicleVariants</h2>
      </div>
      <div class="row">
        <button
          @click="toggleAddVehicleVariants()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <br />
        <!-- <datatable
          :dataItems="items"
          @rowClicked="editItem"
          :dataColumns="columns"
          :searching="true"
          :sorting="true"
          @editRow="editItem"
          :order="[1, 'dec']"
          class="text-center"
          striped
          bordered
        />
-->

         <DataBlocks
          @itemSelected="editItem"
          :data="items"
          :fields="['Modal','Description']"
        />
              </div> 
    </div>
  </div>
</template>
<script>
import addVehicleVariants from "../../components/addVehicleVariants";
import editVehicleVariants from "../../components/editVehicleVariants";
import VueNotifications from "vue-notifications";
import { Datatable } from "../../mdb/components/Datatable";

import DataBlocks from "@/components/custom/dataBlocks.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
        {
          label: "Id",
          field: "Id",
          sort: "asc",
        },
        {
          label: "Description",
          field: "Description",
          sort: "asc",
        },
        {
          label: "Modal",
          field: "Modal",
          sort: "asc",
        },
        {
          label: "Price",
          field: "Price",
          sort: "asc",
        },
        {
          label: "MetalicPaintPrice",
          field: "MetalicPaintPrice",
          sort: "asc",
        },
        {
          label: "PowerKW",
          field: "PowerKW",
          sort: "asc",
        },
        {
          label: "TorqueNM",
          field: "TorqueNM",
          sort: "asc",
        },
        {
          label: "Displacement",
          field: "Displacement",
          sort: "asc",
        },
        {
          label: "MaxSpeed",
          field: "MaxSpeed",
          sort: "asc",
        },
        {
          label: "Transmission",
          field: "Transmission",
          sort: "asc",
        },
        {
          label: "FuelConsumption",
          field: "FuelConsumption",
          sort: "asc",
        },
        {
          label: "FuelType",
          field: "FuelType",
          sort: "asc",
        },
        {
          label: "BootSpace",
          field: "BootSpace",
          sort: "asc",
        },
        {
          label: "PowerWindows",
          field: "PowerWindows",
          sort: "asc",
        },
        {
          label: "Airbags",
          field: "Airbags",
          sort: "asc",
        },
        {
          label: "ABS",
          field: "ABS",
          sort: "asc",
        },
        {
          label: "CentralLocking",
          field: "CentralLocking",
          sort: "asc",
        },
        {
          label: "FogLamps",
          field: "FogLamps",
          sort: "asc",
        },
        {
          label: "BoreStroke",
          field: "BoreStroke",
          sort: "asc",
        },
        {
          label: "KeyFeatures",
          field: "KeyFeatures",
          sort: "asc",
        },
        {
          label: "Features",
          field: "Features",
          sort: "asc",
        },
        {
          label: "SeatingCapacity",
          field: "SeatingCapacity",
          sort: "asc",
        },
        {
          label: "BodyType",
          field: "BodyType",
          sort: "asc",
        },
        {
          label: "LastUpdated",
          field: "LastUpdated",
          sort: "asc",
        },
        {
          label: "TechnicalFeatures",
          field: "TechnicalFeatures",
          sort: "asc",
        },
        {
          label: "PerformanceFeatures",
          field: "PerformanceFeatures",
          sort: "asc",
        },
        {
          label: "ConvenienceFeatures",
          field: "ConvenienceFeatures",
          sort: "asc",
        },
        {
          label: "StylingFeatures",
          field: "StylingFeatures",
          sort: "asc",
        },
        {
          label: "SafetyFeatures",
          field: "SafetyFeatures",
          sort: "asc",
        },
        {
          label: "WarrantyAndServiceFeatures",
          field: "WarrantyAndServiceFeatures",
          sort: "asc",
        },
        {
          label: "TechnicalFeaturesOrder",
          field: "TechnicalFeaturesOrder",
          sort: "asc",
        },
        {
          label: "PerformanceFeaturesOrder",
          field: "PerformanceFeaturesOrder",
          sort: "asc",
        },
        {
          label: "ConvenienceFeaturesOrder",
          field: "ConvenienceFeaturesOrder",
          sort: "asc",
        },
        {
          label: "StylingFeaturesOrder",
          field: "StylingFeaturesOrder",
          sort: "asc",
        },
        {
          label: "SafetyFeaturesOrder",
          field: "SafetyFeaturesOrder",
          sort: "asc",
        },
        {
          label: "WarrantyAndServiceFeaturesOrder",
          field: "WarrantyAndServiceFeaturesOrder",
          sort: "asc",
        },
        {
          label: "Image",
          field: "Image",
          sort: "asc",
        },
      ],
      items: [],
      addVehicleVariantsOpen: false,
      editVehicleVariantsOpen: false,
      currentVehicleVariants: {},
    };
  },
  components: {
    addVehicleVariants,
    editVehicleVariants,
    Datatable,
    DataBlocks,
  },
  created: function () {
    this.getVehicleVariants();
    this.addVehicleVariantsOpen = false;
    this.editVehicleVariantsOpen = false;
  },
  methods: {
    itemSelected(item){

    },
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    getVehicleVariants() {
      var payload = {
        success: (response) => {
          this.mapVehicleModels(response.data);
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getVehicleVariants", payload);
    },
    mapVehicleModels(variants) {
      var payload = {
        success: (response) => {
          var variantsNew = [];
          for (const iterator of variants) {
            
            var modal = response.data.find((g) => g.Id == iterator.ModelId);
            if (modal) {
              iterator.Modal = modal.Name;            
            }  
            variantsNew.push(iterator);
          }
          
          this.items = variantsNew;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getVehicleModels", payload);
    },
    editItem(VehicleVariants) {
      this.toggleEditVehicleVariants();
      this.currentVehicleVariants = VehicleVariants;
    },
    toggleAddVehicleVariants() {
      if (this.addVehicleVariantsOpen) {
        this.$refs.addVehicleVariantsModal.hide();
      } else {
        this.$refs.addVehicleVariantsModal.show();
      }
      this.addVehicleVariantsOpen = !this.addVehicleVariantsOpen;
    },
    addVehicleVariantsSuccess() {
      this.toggleAddVehicleVariants();
      miniToastr["success"]("VehicleVariants Added", "Success", 1000, null);
      this.getVehicleVariants();
    },
    toggleEditVehicleVariants() {
      if (this.editVehicleVariantsOpen) {
        this.$refs.editVehicleVariantsModal.hide();
      } else {
        this.$refs.editVehicleVariantsModal.show();
      }
      this.editVehicleVariantsOpen = !this.editVehicleVariantsOpen;
    },
    editVehicleVariantsSuccess() {
      this.toggleEditVehicleVariants();
      miniToastr["success"]("VehicleVariants Edited", "Success", 1000, null);
      this.getVehicleVariants();
    },
  },
};
</script>
<style scoped>
.VehicleVariantsThumbnail {
  height: 50px;
}
</style>
